import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import CompetitionPageComponent from "../components/CompetitionPageComponent"

const CompetitionPage = ({ data }) => (
  <Layout>
    <SEO title="Competition" />
    <CompetitionPageComponent compeWinners={data.allCompeDescJson.nodes} />
  </Layout>
)

export const query = graphql`
  query CompeWinnerQuery {
    allCompeDescJson {
      nodes {
        sub_compe {
          name
          champion_list {
            rank
            prize
          }
        }
      }
    }
  }
`

export default CompetitionPage
